import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'tools/i18n';
import NewportForm from '@components/forms/NewportForm';
import NewportInput from '@components/forms/NewportInput';
import theme from '@components/theme';
import NewportSociety from '@public/images/logo/newport-society.svg';
import CallToActionButton from '@components/products/partials/CallToActionButton';
import { Checkbox } from '@material-ui/core';
import { useState } from 'react';
import useUserStore from '@services/stores/UserStore';
import NewportCountrySelector from '@components/forms/NewportCountrySelector';

const styles = {
    registerHeader: {
        display: 'flex',
        justifyContent: 'center',
        margin: '-20px -20px 30px -20px',
        height: 150,
        borderBottom: '2px solid ' + theme.palette.primary.lightest,
        '& svg': {
            maxWidth: 200,
            filter: 'invert(1)',
        },
        [theme.breakpoints.up('md')]: {
            margin: '-55px -20px 30px -20px',
        },
    },
    fields: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
    },
    form: {
        margin: '0 auto',
        width: '100%',
        maxWidth: 400,
    },
    title: {
        fontSize: 18,
        textTransform: 'uppercase',
        textAlign: 'center',
    },
    signupButton: {
        marginTop: 30,
    },
    checkbox: {
        display: 'grid',
        gridTemplateColumns: 'min-content 1fr',
        alignItems: 'center',
        marginTop: 20,
        '& > span': {
            marginLeft: -12,
            marginTop: -18,
        },
    },
};
const useStyles = makeStyles(() => styles, { name: 'AccountSignup' });

const initialPayload = {
    first_name: '',
    last_name: '',
    country_code: '',
    email: '',
    password: '',
    consent: false,
    website: '',
};

export default function AccountSignup({ onGoBack = null }) {
    const user = useUserStore(state => state);
    const classes = useStyles();
    const { t } = useTranslation();
    const [ payload, setPayload ] = useState(initialPayload);
    const service = useUserStore((state) => state.service);
    const [ errors, setErrors ] = useState(null);
    const [loading, setLoading] = useState(false);

    function updateField(field, value) {
        payload[field] = value;
        setPayload(prev => ({ ...prev, [field]: value }));
    }

    async function register(event) {
        event.preventDefault();
        event.stopPropagation();

        payload.website = location.hostname;
        payload.password_confirmation = payload.password;
        setLoading(true);
        const result = await service.registerNewAccount(payload).finally((res) => {
            setLoading(false);
        });

        if (result.status === 'error' && result.response.status === 422) {
            const json = await result.response.json();
            setErrors(json.errors);
            return;
        }

        if (result?.data?.auth) {
            user.login(result.data.user, result.data.auth);
        }
    }

    function error(key) {
        return errors?.[key];
    }

    return <>
        <header className={classes.registerHeader}>
            <NewportSociety></NewportSociety>
        </header>
        <NewportForm className={classes.form}>
            <h2 className={classes.title}>{t('account:form-signup-title')}</h2>
            <div className={classes.fields}>
                <NewportInput
                    label={t('account:first-name')}
                    type={'text'}
                    errors={error('first_name')}
                    onChange={(e) => updateField('first_name', e.target.value)}
                ></NewportInput>
                <NewportInput
                    label={t('account:last-name')}
                    type={'text'}
                    errors={error('last_name')}
                    onChange={(e) => updateField('last_name', e.target.value)}
                ></NewportInput>
                <NewportCountrySelector
                    label={t('country')}
                    errors={error('country_code')}
                    onChange={(changeEvent, value) => {
                        updateField('country_code', value.code);
                    }}
                ></NewportCountrySelector>
                <NewportInput
                    label={t('general:email')}
                    type={'email'}
                    errors={error('email')}
                    onChange={(e) => updateField('email', e.target.value)}
                ></NewportInput>
                <NewportInput
                    label={t('general:password')}
                    type={'password'}
                    errors={error('password')}
                    onChange={(e) => updateField('password', e.target.value)}
                ></NewportInput>
            </div>
            <div className={classes.fields}>
                <div className={classes.checkbox}>
                    <Checkbox
                        checked={payload.consent}
                        onChange={() => updateField('consent', !payload.consent)}>
                    </Checkbox>
                    {t('account:checkbox-accept-terms-and-conditions')}
                </div>
            </div>
            <CallToActionButton
                disabled={!payload.consent}
                className={classes.signupButton}
                onClick={register}
                onSubmit={register}
                isLoading={loading}
            >
                {t('account:signup-button')}
            </CallToActionButton>
            <CallToActionButton
                colorScheme={'bg-white'}
                onClick={() => onGoBack ? onGoBack() : null}
            >
                {t('general:go-back')}
            </CallToActionButton>
        </NewportForm>
    </>;
}
