import NewportInput from '@components/forms/NewportInput';
import CallToActionButton from '@components/products/partials/CallToActionButton';
import useUserStore from '@services/stores/UserStore';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from '@tools/i18n';
import { useState } from 'react';
import NewportForm from '@components/forms/NewportForm';

const styles = {
    addEmail: {
        padding: '0 20px 20px',
        background: '#00000009'
    },
    combo: {
        display: 'grid',
        gridTemplateColumns: '1fr 200px',
        justifyContent: 'end',
    },
};

const useStyles = makeStyles(() => styles, { name: 'Account' });

export default function AccountCompleteEmail() {
    const user = useUserStore();
    const classes = useStyles();
    const { t } = useTranslation([ 'account' ]);
    const [ email, setEmail ] = useState(null);
    const [ errors, setErrors ] = useState(null);
    const [ errorMessage, setErrorMessage ] = useState(null);
    const service = user.getService();

    async function handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();
        setErrors(null);
        setErrorMessage(null)
        const response = await service.setEmail(email);

        // Handle validation errors (e.g., 422 Unprocessable Entity)
        if (response?.response?.status === 422) {
            const payload = await response.response.json();
            setErrors(payload.errors);
            setErrorMessage(payload.message);
            return;
        }

        if (response.response.status === 400) {
            const payload = await response.response.json();
            setErrorMessage(payload?.message);
            console.log(payload);
            return;
        }

        user.init().then();
    }

    return <div className={classes.addEmail}>
        <p>
            <b>{t('account:complete-your-registration-add-email')}</b>
        </p>
        <NewportForm className={classes.combo} onSubmit={handleSubmit}>
            <NewportInput
                label={t('account:email')}
                onChange={(event) => {
                    setEmail(event.target.value);
                }}
                errors={errors?.['email'] || errorMessage ? [errorMessage] : null}
            ></NewportInput>
            <CallToActionButton
                style={{marginTop: 17}}
                size={'form'}
                type={'submit'}
            >{t('account:add-email')}</CallToActionButton>
        </NewportForm>
    </div>
}
